import {
  DownloadOutlined,
  LoadingOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { Button, FormInstance, message, Modal, Upload } from 'antd'
import type { UploadChangeParam } from 'antd/es/upload'
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface'
import { ButtonType } from 'antd/lib/button'
import { ILicenseMedia, INewFileList } from 'components/License'
import { Media } from 'interfaces/media'
import { useTranslations } from 'next-intl'
import { stringify } from 'qs'
import React, { useCallback, useState } from 'react'
import axiosInstance from 'utils/axiosInstance'
import { getResponseData, getResponseErrorMessage } from 'utils/commonUtils'

interface IProps {
  setMedia: (data: Media | any) => void
  fieldName?: string
  form?: FormInstance
  maxCount?: number
  dataMedia?: ILicenseMedia[]
  isOnReview?: boolean
  isDownload?: boolean
  fileList?: INewFileList[]
  className?: string
  type?: ButtonType
  isShowMessage?: boolean
  disabled?: boolean
  isRemove?: boolean
  endPoint?: string
}
const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = (error) => reject(error)
  })

const MediaFileUpload: React.FC<IProps> = ({
  setMedia,
  fieldName,
  form,
  maxCount = 1,
  dataMedia,
  isOnReview = false,
  isDownload = false,
  disabled = false,
  fileList,
  className = 'mb-5',
  type = 'default',
  isShowMessage = true,
  endPoint = '/v1/media',
}) => {
  const [loading, setLoading] = useState(false)
  const [nextFileList, setNextFileList] = useState<UploadFile[]>(
    fileList as any
  )

  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [previewTitle, setPreviewTitle] = useState('')
  const translate = useTranslations()

  const beforeUpload = (file: RcFile) => {
    const isLt10M = file.size / 1024 / 1024 < 10
    if (!isLt10M && isShowMessage) {
      message.error(translate('common.errorFileSize'))
      onRemove()
    }
    return isLt10M
  }

  const handleChange: UploadProps['onChange'] = (
    info: UploadChangeParam<UploadFile>
  ) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
    }
    if (info.file.status === 'done') {
      setLoading(false)

      setNextFileList(info.fileList)
      isShowMessage &&
        message.success(
          `${info.file.name} ${translate('common.uploadSuccess')}`
        )
    }
    setNextFileList(info.fileList)
  }

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options

    const fmData = new FormData()
    fmData.append('file', file)
    try {
      const res = await axiosInstance.post(endPoint, fmData)
      const data = getResponseData<Media>(res)
      form?.setFieldsValue({ [fieldName]: data?.id })
      if (maxCount > 1) {
        const mediaID = data?.id
        const newMedia = {
          mediaID,
          name: data?.name,
          type: fieldName,
        }
        setMedia([...dataMedia, newMedia])
      } else setMedia(data)

      onSuccess('Ok')
    } catch (err) {
      onError({ err })
      setLoading(false)
    }
  }

  const onRemove = (media?: any) => {
    if (maxCount > 1) {
      const nextMedia = dataMedia?.filter(
        (i) => i?.type === fieldName && i?.name !== media?.name
      )
      const existMedia = dataMedia?.filter((i) => i?.type !== fieldName)
      setMedia([...existMedia, ...nextMedia])
    } else setMedia(null)
    form?.setFieldsValue({ [fieldName]: null })
  }

  const onPreview = async (file: UploadFile) => {
    if (file?.type?.includes('image/')) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj as RcFile)
      }
      setPreviewImage(file.url || (file.preview as string))
      setPreviewOpen(true)
      setPreviewTitle(
        file.name || file.url!.substring(file.url!.lastIndexOf('/') + 1)
      )
    } else getFileUrl(file?.uid, file?.name)
  }

  const getFileUrl = useCallback(async (mediaID, name) => {
    try {
      const downloadParam = stringify({
        mediaID,
      })

      const {
        data: {
          data: { url },
        },
      } = await axiosInstance.get(`/v1/media/downloads?${downloadParam}`)

      if (url) {
        if (name.includes('.png') || name.includes('.jpg')) {
          setPreviewOpen(true), setPreviewImage(url), setPreviewTitle(name)
        }
      }
    } catch {
      //do something
    }
  }, [])

  const onDownload = async (file: UploadFile) => {
    const mediaID = dataMedia?.filter(
      (i) => i?.type === fieldName && i?.name === file?.name
    )?.[0]?.mediaID
    if (mediaID)
      try {
        const downloadParam = stringify({
          mediaID,
        })

        const {
          data: {
            data: { url },
          },
        } = await axiosInstance.get(`/v1/media/downloads?${downloadParam}`)

        const link = document.createElement('a')
        if (link) {
          link.href = url
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        }
      } catch (error) {
        const err = getResponseErrorMessage(error)
        message.error(
          typeof err === 'string' ? err : 'Failed to download image!'
        )
      }
  }

  const handleCancel = () => {
    setPreviewOpen(false), setPreviewImage(null)
  }

  const showUploadList = {
    showDownloadIcon: isDownload,
    showRemoveIcon: !disabled,
    downloadIcon: <DownloadOutlined />,
  }

  return (
    <div className={className}>
      <Upload
        name="mediaFile"
        beforeUpload={beforeUpload}
        onChange={handleChange}
        customRequest={uploadImage}
        maxCount={maxCount}
        onRemove={onRemove}
        onDownload={onDownload}
        showUploadList={showUploadList}
        onPreview={isOnReview && onPreview}
        fileList={nextFileList}
      >
        <Button
          type={type}
          icon={loading ? <LoadingOutlined /> : <UploadOutlined />}
          disabled={nextFileList?.length === maxCount || disabled}
        >
          {translate('common.upload')}
        </Button>
      </Upload>

      <Modal
        visible={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
        width="70vw"
      >
        <div>
          <img alt="" style={{ width: '100%' }} src={previewImage} />
        </div>
      </Modal>
    </div>
  )
}

export default MediaFileUpload
