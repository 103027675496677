import {
  AGENT_GROUP,
  AGENT_INCENTIVES,
  AGENTS_PERFORMANCE,
  AUTO_INVENTORY_REPLENISHMENT,
  AUTOMATIC_PARTIAL_FULFILLMENT,
  AUTOMATIC_PROCUREMENT_CONFIG,
  BIDDING_INVITATION_LIST,
  BIDDING_REQUEST,
  BRAND_PORTAL,
  BUY_IN_INVOICE,
  CATEGORY_INSIGHTS,
  CLAIMS,
  CLAIMS_INVOICE,
  CLAIMS_MANAGEMENT,
  CONFIG_CM3_TARGET,
  CONSOLIDATED_PURCHASE_REQUEST,
  COVERAGE_REPORT,
  CREDIT,
  CREDIT_DEBIT_NOTE,
  DELIVERY_MANAGEMENT,
  DELIVERY_MANAGEMENT_HUB,
  DELIVERY_PERFORMANCE,
  DISTRIBUTOR_CENTER,
  DISTRIBUTOR_CONTRACT_FEE,
  DISTRIBUTOR_LIST,
  DISTRIBUTOR_MANAGEMENT,
  DISTRIBUTOR_MARGIN,
  DISTRIBUTOR_PROFILE,
  DISTRIBUTOR_PURCHASE_ORDER,
  DISTRIBUTOR_PURCHASE_REQUEST,
  DISTRIBUTOR_REGISTER,
  FAILED_DELIVERY_RECEIVE,
  FINANCE,
  FINANCE_TASK_LIST,
  FULFILLMENTS,
  GOODS_NOT_FOCUS,
  HOME,
  INBOUND_LIST,
  INCENTIVES_CONFIG,
  INVENTORY_ADJUSTMENT,
  INVENTORY_LIST,
  INVENTORY_RECOMMEND,
  INVENTORY_REPORT,
  LOGISTICS,
  MARKETING,
  MARKETING_BUDGET,
  MASTER_DATA,
  MENU_PRODUCT,
  MISSION_PERFORMANCE,
  NOTIFICATION,
  ORDER_LIST,
  OUTLET_MANAGEMENT,
  OUTLETS_PERFORMANCE,
  PACKAGE_LIST,
  PARTNER,
  PAYMENT_REQUEST,
  PAYOUT_STATEMENT,
  PICKLIST_ASSIGNMENT,
  PICKLIST_CHECKOUT,
  PRE_ORDERS,
  PRICE_MANAGEMENT,
  PRICE_TYPE_CONFIG,
  PROCUREMENT,
  PROCUREMENT_CONFIG,
  PRODUCT_AVAILABLE,
  PRODUCT_LIST,
  PRODUCT_LIST_VN,
  PRODUCT_LISTING,
  PRODUCT_PRE_ORDER,
  PRODUCT_PRE_ORDER_INDIA,
  PRODUCT_TRADING_BY_LISTING,
  PURCHASE_ORDER_LIST,
  PURCHASING,
  REASON_CANCEL,
  REASON_NEED_ADJUST,
  REMITTANCE_LIST,
  REPORT,
  REPORT_CLAIM,
  REPORT_CLAIMS,
  REPORT_CLAIMS_RETURN,
  REPORT_DISTRIBUTOR_RECONCILIATION,
  REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY,
  REPORT_GST_PURCHASE_REGISTER,
  REPORT_GST_PURCHASES,
  REPORT_GST_SALES,
  REPORT_GST_SALES_ADMIN,
  REPORT_GST_SALES_HUB,
  REPORT_GST_SALES_REGISTER_ADMIN,
  REPORT_GST_SALES_REGISTER_DETAILED_HUB,
  REPORT_GST_SALES_REGISTER_HUB,
  REPORT_GST_SALES_RETURN,
  REPORT_GST_SALES_RETURN_ADMIN,
  REPORT_GST_SALES_RETURN_HUB,
  REPORT_GST_SUMMARY_BY_HSN,
  REPORT_GSTR_SALES_REGISTER,
  REPORT_INVENTORY,
  REPORT_INVENTORY_SNAPSHOT,
  REPORT_INVOICE_LOADING,
  REPORT_PRODUCT_SUMMARY,
  REPORT_PURCHASE_RECONCILIATION,
  REPORT_PURCHASE_REGISTER_DETAILED,
  REPORT_PURCHASE_RETURN,
  REPORT_RIDER,
  REPORT_SALES_SUMMARY,
  REPORT_SALES_SUMMARY_OUTLET,
  REPORT_SALES_SUMMARY_SKU,
  REPORT_SUMMARY,
  RETAIL_PURCHASE_REQUEST_LIST,
  RETURN,
  RETURN_ORDER_LIST,
  RIDER,
  RIDER_MANAGEMENT,
  SALES_PERFORMANCE,
  SELL_OUT_INVOICE,
  SETTINGS,
  STOCK_TRANSFER,
  SUBSIDY_RULE,
  SUPPLIER_INVOICE_PRODUCT_MANAGEMENT,
  SUPPLIER_LIST,
  SUPPLIER_LIST_VN,
  SUPPLIER_PORTAL,
  SUPPLIER_PRODUCT_LIST,
  SUPPLIER_PRODUCT_LIST_VN,
  TASK_LIST,
  TAX_MANAGEMENT,
  TRADE_ORDERS,
  TRANSPORTATION_VENDOR,
  USER_LIST,
  VEHICLE_MANAGEMENT,
  VIGO_DELIVERY,
  VIGO_MASTER_QUOTATION,
  VIGO_PURCHASE_ORDER,
  WAREHOUSE_LIST,
  WAREHOUSE_WMS_LIST,
  WMS,
} from 'common/config/routes'
import { isIndia } from 'utils/commonUtils'
import {
  BIDDING_INVITATION_KEY,
  BIDDING_REQUEST_KEY,
  BUDGET,
  BUY_IN_INVOICE_KEY,
  COMMON,
  CONSOLIDATED_PR,
  CONTRACT_FEE,
  DELIVERY,
  DISTRIBUTOR_PROFILE_MANAGEMENT,
  DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB,
  INBOUND,
  INVENTORY,
  ORDERS,
  PACKAGE,
  PAYMENT_REQUEST_BREADCRUMB,
  PICKLIST,
  PO,
  PRE_ORDERS_TRANSLATE,
  PRODUCT,
  PURCHASE_REQUEST,
  REMITTANCE,
  RIDER_REPORT,
  RIDERS,
  SA_PAYOUT_STATEMENT_DETAIl,
  SELL_OUT_INVOICE_KEY,
  SIGN_UP,
  SUBMIT_BIDDING,
  SUBSIDY,
  TRADE_ORDERS_TRANSLATE,
  UPDATE_WAREHOUSE_DETAILS,
  VEHICLE,
  VIGO_MASTER,
  VIGO_PO,
  VIGO_QUOTATION,
  WAREHOUSE,
} from 'utils/localUtils'
import {
  WORKING_DAY_FRIDAY,
  WORKING_DAY_MONDAY,
  WORKING_DAY_SATURDAY,
  WORKING_DAY_SUNDAY,
  WORKING_DAY_THURSDAY,
  WORKING_DAY_TUESDAY,
  WORKING_DAY_WEDNESDAY,
} from 'utils/supplier'

import { PURCHASING_EN } from './distributor-center/purchasing'
import { DISTRIBUTOR_PURCHASE_ORDER_EN } from './distributor-center/purchasing/distributor-purchase-order'
import { PAYMENT_REQUEST_EN } from './distributor-center/purchasing/payment-request'
import { PURCHASE_REQUEST_EN } from './distributor-center/purchasing/purchase-request'
import { VIGO_QUOTATION_EN } from './distributor-center/purchasing/vigo-quotation'
import { DISTRIBUTOR_PROFILE_EN } from './distributor-profile'
import { BUY_IN_INVOICE_EN } from './finance/buy-in-invoice'
import { DISTRIBUTOR_CONTRACT_FEE_EN } from './finance/distributor-contract-fee'
import { SELL_OUT_INVOICE_EN } from './finance/sell-out-invoice'
import { ORDERS_EN } from './fulfillment/orders'
import { PICKLIST_EN } from './fulfillment/picklist'
import { PRE_ORDERS_EN } from './fulfillment/pre-orders'
import { TRADE_ORDERS_EN } from './fulfillment/tradeOrder'
import { HOME_EN } from './home'
import { BUDGET_EN } from './marketing/budget'
import { SUBSIDY_EN } from './marketing/subsidy'
import { PRODUCT_EN } from './master-data/product-available'
import { PRODUCT_LISTINGS_EN } from './master-data/product-listings'
import { PRODUCT_PRE_ORDER_EN } from './master-data/product-pre-order'
import { PRODUCT_TRADING_BY_LISTING_EN } from './master-data/product-trading-by-listings'
import { REASON_NEED_ADJUST_EN } from './master-data/reasonNeedAdjust'
import { AUTOMATIC_CONFIG_EN } from './procurement/automatic-procurement-config'
import { CONSOLIDATED_PR_EN } from './procurement/consolidatedPR'
import { VIGO_MASTER_QUOTATION_EN } from './procurement/vigo-master-quotation'
import { VIGO_PURCHASE_ORDER_EN } from './procurement/vigo-purchase-order'
import { REPORT_RIDER_EN } from './report/rider'
import { DELIVERY_EN } from './rider/delivery'
import { PACKAGE_EN } from './rider/package'
import { REMITTANCE_EN } from './rider/remittance'
import { RIDER_EN } from './rider/rider'
import { VEHICLE_EN } from './rider/vehicle'
import { TASK_EN } from './settings/tasks'
import { SIGN_UP_EN } from './sign-up'
import { BIDDING_INVITATION_EN } from './supplier-portal/bidding-invitations'
import { BIDDING_REQUEST_EN } from './supplier-portal/bidding-request'
import { PO_EN } from './supplier-portal/purchase-order'
import { AUTO_INVENTORY_REPLENISHMENT_EN } from './wms/auto-inventory-replenishment'
import { INBOUND_EN } from './wms/inbound'
import { INVENTORY_EN } from './wms/inventory'
import { STOCK_TRANSFER_EN } from './wms/stock-transfer'
import { WAREHOUSE_EN } from './wms/warehouse'

const common = {
  welcome: 'Welcome back',
  email: 'Email',
  password: 'Password',
  login: 'Login',
  loginGoogle: 'Login with Google',
  or: 'or',
  emailRequired: 'Email is required!',
  passwordRequired: 'Password is required!',
  logout: 'Logout',
  changePassword: 'Change Password',
  prev: 'Prev',
  next: 'Next',
  records: 'records',
  page: 'Page',
  validateEmail: 'Please input valid email!',
  statusHistory: 'Status History',
  no: 'NO.',
  status: 'Status',
  createdBy: 'Created By',
  createdDate: 'Created Date',
  updatedBy: 'Updated By',
  updatedDate: 'Updated Date',
  messageErrorPassword: 'Username / Password is incorrect!',
  action: 'Action',
  search: 'Search',
  refresh: 'Refresh',
  reset: 'Reset',
  update: 'Update',
  cancel: 'Cancel',
  save: 'Save',
  ok: 'OK',
  delete: 'Delete',
  deleteConfirm: 'Are you sure you want to delete this?',
  locale: 'en',
  '/': 'Home',
  detail: 'Detail',
  create: 'Create',
  edit: 'Edit',
  create_retail_po: 'Create Retail Purchase Order',
  actions: 'Actions',
  warehouseCode: 'Warehouse Code',
  createNew: 'Create New',
  poNumber: 'PO Number',
  inboundNumber: 'Inbound Number',
  invoiceNumber: 'Invoice Number',
  distributor: 'Distributor',
  distributorCode: 'Distributor Code',
  selectDistributor: 'Select Distributor',
  warehouse: 'Warehouse',
  selectWarehouse: 'Select Warehouse',
  supplier: 'Supplier',
  selectSupplier: 'Select Supplier',
  selectStatus: 'Select Status',
  startDate: 'Start Date',
  endDate: 'End Date',
  approvedDate: 'Approved Date',
  approvedBy: 'Approved By',
  uploadFile: 'Upload File',
  fileSizeMsg: 'File size must be less than {size} MB',
  uploading: 'Uploading',
  startUpload: 'Start Upload',
  chooseFile: 'Choose File',
  downloadFile: 'Download File',
  invoiceDate: 'Invoice Date',
  uploadMessage: {
    success: 'Upload file successfully!',
    error: 'Upload file failed!',
  },
  downloadMessage: {
    error: 'Download file failed!',
  },
  updateMessage: {
    success: 'Update {name} successfully!',
    error: 'Update {name} failed!',
  },
  createMessage: {
    success: 'Create {name} successfully!',
    error: 'Create {name} failed!',
  },
  deleteMessage: {
    success: 'Delete {name} successfully!',
    error: 'Delete {name} failed!',
  },
  cancelMessage: {
    success: 'Cancel {name} successfully!',
    error: 'Cancel {name} failed!',
  },
  getMessage: {
    success: 'Get {name} successfully!',
    error: 'Get {name} failed!',
  },
  exportMessage: {
    success: 'Export Success!',
    detail: 'Click here to download the file',
    error: 'Export Failed!',
  },
  searchOrEnterName: 'Select of enter name to search',
  actionMessage: {
    success: '{name} successfully!',
    error: '{name} failed!',
  },
  required: '{name} is required!',
  requiredInput: 'Please input {name}',
  requiredUpload: 'Please upload {name}!',
  requiredSelect: 'Please select {name}!',
  errorMessage: 'Oops! Something went wrong.',
  selectDate: 'Select Date',
  poPrice: 'PO Price',
  shippingFee: 'Shipping Fee',
  productName: 'Product Name',
  product: 'Product',
  searchProduct: 'Search product',
  quantity: 'Quantity',
  nationalDrugCode: 'National Drug Code',
  batchNumber: 'Batch Number',
  shelfLifeMonth: 'Shelf Life (Month)',
  manufacturingDate: 'Manufacturing Date',
  expirationDate: 'Expiration Date',
  acceptedQty: 'Accepted Qty',
  rejectedQty: 'Rejected Qty',
  rejectedReason: 'Rejected Reason',
  packageType: 'Package Type',
  actualPrice: 'Actual Price',
  vatPrice: 'VAT Price',
  totalAmount: 'Total Amount',
  vatTotalAmount: 'VAT Total Amount',
  priceDiff: 'Price Diff',
  total: 'Total',
  totalQty: 'Total Quantity',
  submit: 'Submit',
  batchExistMessage: 'Batch already exists!',
  error: 'Error',
  exportType: {
    csv: 'Export to CSV',
    xlsx: 'Export to MS Excel',
    xlsx_complete: 'Export to MS Excel and complete',
  },
  brand: 'Brand',
  category: 'Category',
  orderNumber: 'Order Number',
  tradeOrderNumber: 'Trade Order Number',
  image: 'Image',
  recordsFound: 'records found',
  district: 'District',
  export: 'Export',
  receiver: 'Receiver',
  sender: 'Sender',
  addNew: 'Add New',
  outlet: 'Outlet',
  outletName: 'Outlet Name',
  agentName: 'Agent Name',
  package: 'Package',
  name: 'Name',
  phone: 'Phone',
  address: 'Address',
  latitude: 'Latitude',
  longitude: 'Longitude',
  tags: 'Tags',
  limitDateMsg:
    'The distance from the Start Date to the End Date should not be more than {day} days!',
  confirm: 'Confirm',
  reject: 'Reject',
  unknown: 'Unknown',
  undefined: '-',
  null: '-',
  invalid: 'Invalid',
  reason: 'Reason',
  otherReason: 'Other Reason',
  moreFilter: 'More Filter',
  distributorValidMsg: 'Email: {email} is not a distributor!',
  view: 'View',
  download: 'Download',
  noData: 'No Data',
  addNewStock: 'New Stock',
  updateStockHistory: 'Update Stock History',
  updateStock: 'Update Stock',
  stock: 'Stock',
  noOfCase: 'No of Case',
  noOfPiece: 'No of Piece',
  oldStock: 'Old Stock(Case/Piece)',
  newStock: 'New Stock(Case/Piece)',
  updateSuccessful: 'Update Successful',
  note: 'Note',
  selectProvince: 'Select province',
  validateNoOfPiece: 'No of Piece must be less than pack size',
  requiredQuantity: 'Please input quantity!',
  currentStock: 'Current Stock(Case/Piece)',
  packSize: 'Pack Size',
  processType: 'Process Type',
  trading: 'Trading',
  retail: 'Retail',
  trading_by_listing: 'Trading By Listing',
  consignment: 'Consignment',
  selectProcessType: 'Select process type',
  upload: 'Upload',
  uploadSuccess: 'file uploaded successfully',
  errorFileSize: 'File must smaller than 10MB!',
  messageConfirmChangePassword:
    'Attention: Session of all devices will be force login again',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  newPasswordConfirm: 'New Password Confirm',
  requireCurrentPassword: 'Please input current password!',
  requireNewPassword: 'Please input new password!',
  requireNewPasswordConfirm: 'Please confirm your password!',
  passwordNotMatch: 'The two passwords that you entered do not match!',
  messageErrorOldPassword: 'Old password does not match',
  passwordUpdated: 'Password updated',
  updateFailed: 'Update failed!',
  createFailed: 'Create failed!',
  apply: 'Apply',
  brandGroup: 'Brand Group',
  productSku: 'Product - SKU',
  updatedCreatedDate: 'Updated / Created Date',
  on: 'ON',
  off: 'OFF',
  sku: 'SKU',
  active: 'Active',
  updateHistory: 'Update History',
  isLast7Days: 'Last 7 days',
  isLast30Days: 'Last 30 days',
  unit: 'Unit',
  inActive: 'In-Active',
  all: 'All',
  fulfillmentOrderNumber: 'Fulfillment Order Number',
  updated: 'Updated',
  tradeOrderManagement: 'Trade Order Management',
  preOrderManagement: 'Pre Order Management',
  tradeOrder: 'Trade Order',
  tradeOrderCreatedDate: 'Trade Order Created Date',
  fulfillmentOrderCreatedDate: 'Fulfillment Order Created Date',
  orderType: 'Order Type',
  items: 'Items',
  generalInformation: 'General Information',
  shippingType: 'Shipping Type',
  activeStatus: 'Active',
  allStatus: 'All status',
  newPaginationGoTo: 'Go to',
  totalItems: 'Total {items} items',
  updatedDateLabel: 'Updated',
  copyData: 'Copy Data',
  nameProduct: 'Name',
  minimumOrderQuantity: 'Minimum Order Quantity',
  referralPrice: 'Referral Price',
  importData: 'Import data',
  exportData: 'Export Data',
  selectCategory: 'Select Category',
  clear: 'Clear',
  warehouseManagement: 'Warehouse Management',
  code: 'Code',
  nameWarehouse: 'Name',
  fssai: 'FSSAI Number',
  isPickupLocation: 'Is Pickup Location',
  isWholesaleHub: 'Is Wholesale Hub',
  contactName: 'Contact Name',
  contactPhone: 'Contact Phone',
  contactEmail: 'Contact Email',
  totalPricePO: 'Total Price Purchase Order Per Day',
  createdAt: 'Created At',
  createNewWarehouse: 'Create New Warehouse',
  updateWarehouse: 'Update Warehouse',
  contactInformation: 'Contact Information',
  addressInformation: 'Address Information',
  regionSupport: 'Region Support',
  postCode: 'Post Code',
  coordinate: 'Coordinate',
  street: 'Street',
  editWarehouse: 'Edit Warehouse',
  deleteWarehouse: 'Delete Warehouse',
  validateMsg: {
    less: '{name} must be less than {qty}',
    greater: '{name} must be greater than {qty}',
    lessOrEqual: '{name} must be less than or equal {qty}',
    greaterOrEqual: '{name} must be greater than or equal {qty}',
  },
  subsidyBudget: 'Subsidy Budget',
  totalSubsidyBudget: 'Total Subsidy Budget',
  unitBudget: 'unit',
  budgetManagement: 'Budget Management',
  budgetName: 'Budget Name',
  budgetAmount: 'Budget Amount',
  sourceIncome: 'Source Income',
  promotionTypeSentOn: 'Promotion Type Sent On',
  distributorName: 'Distributor Name',
  ownerEmail: 'Owner Email',
  ownerPhone: 'Owner Phone',
  accountantEmail: 'Accountant Email',
  ownerName: 'Owner Name',
  taxCode: 'Tax Code/GSTIN',
  bankInfo: 'Bank Account Information',
  bankBusiness: 'Bank Account Business',
  bankCollection: 'Bank Account Collection',
  billingAddress: 'Billing Address',
  shippingAddress: 'Shipping Address',
  bank: 'Bank Name',
  accountName: 'Account Name',
  accountNumber: 'Account Number',
  createFrom: 'Create From',
  createTo: 'To',
  saCommission: 'SA Commission',
  saCommissionUnit: 'SA Commission Unit',
  spentAmount: 'Spent Amount',
  createNewTitle: 'Create New {name}',
  profileInfo: 'Profile Information',
  type: 'Type',
  placeholderSelect: 'Select {name}',
  industry: 'Industry',
  groupEmail: 'Group Email',
  fmcg: 'FMCG',
  pharmacy: 'Pharmacy',
  otherInfo: 'Other Info',
  licenseInfo: 'License Info',
  businessRegistration: 'Business Registration',
  licenseQualifyPharmacy: 'License to Qualify for Pharmacy Business',
  licenseStoreGDP: 'License to store GDP',
  bankName: 'Bank Name',
  bankNumber: 'Account Number',
  bankAccountName: 'Account Name',
  branchCode: 'Branch Code',
  addressDetail: 'Address Detail',
  profile: 'Profile',
  updatedInformation: 'Updated Information',
  previousInformation: 'Previous Information',
  needUpdate: 'Need Update',
  approvalDate: 'Approved Date',
  updateInformation: 'Update Information',
  viewHistory: 'View History',
  spentID: 'Spent ID',
  sourceID: 'Source ID',
  entityNumber: 'Budget Code',
  inputToSearch: 'Input {name} to search',
  historyChange: 'History Change',
  invoice: 'Invoice',
  invoice_updated_yes: 'Yes',
  invoice_updated_no: 'No',
  invoiceUpdated: 'Invoice Updated',
  invoiceInformation: 'Invoice Information',
  invoiceUrl: 'Invoice URL',
  invoiceQRCode: 'Invoice QR Code',
  printInvoice: 'Print Invoice',
  extraInformation: 'Extra Information',
  viewReason: 'View Reason',
  reasonCancel: 'Reason Cancel',
  createReasonCancel: 'Create Reason Cancel',
  leadTime: 'Lead Time',
  priceTypeConfig: 'Price Type For Commission Calculation',
  priceType: 'Price Type',
  price: 'Price',
  price_vat: 'Price (VAT)',
  purchase_price: 'Purchase Price',
  purchase_price_vat: 'Purchase Price (VAT)',
  addBatch: 'Add Batch',
  receiverName: 'Receiver Name',
  receiverCode: 'Receiver Code',
  receiverPhone: 'Receiver Phone',
  pre_order_yes: 'Yes',
  pre_order_no: 'No',
  preOrder: 'Pre-Order',
  tradeOrderNumbers: 'Trade Order Numbers',
  add: 'Add item',
  fileExcelError: 'Please check the uploaded file!',
  vat: 'VAT',
  taxManagement: 'Tax Management',
  supplierInvoiceProductMapping: 'Supplier Invoice Product Mapping',
  conversionRate: 'Conversion Rate',
  supplierProductName: 'Supplier Product Name',
  supplierUnit: 'Supplier Unit',
  goodsNotFocus: 'Goods Not Focus',
  condition: 'Condition',
  value: 'Value',
  convertToRetail: 'Convert to Retail',
  notificationConvertToRetail: {
    success: 'Convert to Retail successfully!',
    failed: 'Convert to Retail failed!',
  },
  ratioSalable: 'Ratio Salable',
  ratioAmount: 'Ratio Amount',
  ratioNumber: 'Ratio Number SKU',
  messageRatio: 'Ratio Salable + Ratio Amount + Ratio Number SKU must be 100',
  ward: 'Ward',
  limitRegionSupport: 'Limit Region Support',
  paymentStatus: 'Payment Status',

  finalBatchNumber: 'Final Batch Number',
  prepayment: 'Prepayment',
  pay_after_receive: 'Pay after receive',
  pay_on_duration: 'Pay on duration',
  paymentTerm: 'Payment Term',
  paymentDuration: 'Payment Duration',
  markAsPaid: 'Mark as Paid',
  referenceDocument: 'Reference Document',
  actualAmount: 'Actual Amount',
  massiveCreateSubsidy: 'Massive Create Subsidy',
  deliveryConfiguration: 'Delivery Configuration',
  deliveryMethod: 'Delivery Method',
  separateFulfillmentOrder: 'Separate Fulfillment Order',
  addLimit: 'Add',
  agentPhone: 'Agent Phone',
  newAgentName: 'Agent Name',
  allocatedWarehouse: 'Allocated Warehouse',
  codeNumber: 'Code',
  other: 'Others',
  preOrderNumber: 'Pre order number',
  outletPhone: 'Outlet Phone',
  fullDelivery: 'Full delivery',
  partialDelivery: 'Partial Delivery',
  cancelFilter: 'Cancel',
  confirmFilter: 'Ok',
  today: 'Today',
  this_week: 'This week',
  this_month: 'This month',
  this_quarter: 'This quarter',
  this_year: 'This year',
  outletDetail: 'Outlet Details',
  agentInformation: 'Agent Information',
  noOfSKU: 'No. of SKUs',
  grandTotal: 'Grand Total',
  totalPaid: 'Total Paid',
  totalRefunded: 'Total Refunded',
  totalDue: 'Total Due',
  itemsOrdered: 'Items Ordered',
  outletInformation: 'Outlet Information',
  lat: 'Latitude',
  long: 'Longitude',
  agent: 'Agent',
  agentCode: 'Agent Code',
  loginAccountType: 'Login Account Type',
  deviceID: 'Device ID',
  platform: 'Platform',
  placeFromIP: 'Place from IP',
  latitudeTakingOrder: 'Latitude taking order',
  longitudeTakingOrder: 'Longitude taking order',
  orderInformation: 'Order Information',
  paymentShippingMethod: 'Payment & Shipping Method',
  payment: 'Payment',
  shippingHandling: 'Shipping & Handling',
  grossAmount: 'Gross Amount',
  discount: 'Promo Discounts',
  taxValue: 'Tax Value',
  subsidy: 'Subsidy',
  netAmount: 'Net amount',
  totalRefund: 'Total Refund',
  qtyOrdered: 'Ordered',
  originPrice: 'Original Price (per item)',
  promotionDiscountAmount: 'Promotion Discount Amount',
  subsidyDiscountAmount: 'Distributor subsidy',
  saSubsidy: 'SA Subsidy',
  pricePerQty: 'Price (Per Qty)',
  itemOrderAmountOrdered: 'Item Order Amount (Ordered)',
  itemOrderAmountDelivered: 'Item Order Amount (Delivered)',
  grossSACommissions: 'Gross SA Commission',

  saCommissionAmount: 'SA Commission Amount',
  receiverDetail: 'Receiver Details',
  expand: 'Expand',
  collapse: 'Collapse',
  totalReturnedGoodsValue: 'Total Returns Goods Value',
  qtyDelivered: 'Delivered',
  qtyReturned: 'Returned',
  promotionProgram: 'Promotion Program',
  saCommissionName: 'SA Commission Name',
  distributorSubsidies: 'Distributor Subsidies',
  manualSubsidy: 'Manual Subsidy',
  autoSubsidy: 'Auto Subsidy',
  specialSubsidy: 'Special Subsidy',
  send: 'Send',
  invoiceStatus: 'Invoice Status',
  originalSaCommission: 'Original SA Commission',
  agentPriceSubsidy: 'SA Price Support',
  returnSaCommissionAmount: 'Return SA Commission',
  saCommissionAmountTitle: 'SA Commission Amount',
  new: 'NEW',

  deliverySchedule: 'Delivery Schedule',
  deliveryDay: 'Delivery Day',
  maximumTime: 'Maximum Delivery Time',
  hour: 'Hour',
  saPriceSupport: 'SA Price Support',
  returnSaCommission: 'Return SA Commission',

  subtotal: 'Subtotal',
  welcomeToVigo: 'Welcome to VIGO Distribution Management',
  descWelcome:
    'Log in and start managing your distribution business effectively',
  doNotAcc: `Don't have an account?`,
  signUp: 'Sign up',
  distributorRegisterDetail: 'Distributor Register Details',
  approve: 'Approve',
  area: 'areas',
  noteTradeOrder: 'Note for the order',
  deliveredQuantity: 'Delivered Quantity',
  distributorPriceSubsidy: 'Distributor Price Support',
  orderedQuantity: 'Ordered Quantity',
  originalPrice: 'Original Price',
  returnedQuantity: 'Returned Quantity',
  salesCommissionAmount: 'SA Commission',
  delivered: 'Delivered',
  partial_delivered: 'Partial Delivered',
  statusType: 'Status Type',
  adjustedGrandTotal: 'Adjusted Grand Total',
  receiverType: 'Receiver Type',
  typeOfBusiness: 'Type of business',
  messageSelectSku: 'Click to add <link>one or more SKUs</link> to create',
  updatedStockListing: 'Updated Stock',
  previousStockListing: 'Previous stock',

  itemsDelivered: 'Items Delivered',
  confirmedQuantity: 'Confirmed Quantity',
  dynamicSAcommissions: 'Dynamic SA commissions',
  itemOrderAmount: 'Item Order Amount',
  dynamicCommissionAmount: 'Dynamic Commission Amount',
  net: 'Net',
  qtyConfirmed: 'Confirmed',
  budgetType: 'Budget Type',
  productAvailableOnTheMarket: 'Product Available on the Market',
  productOnMarket: 'Product on Market',
  productRequested: 'Product Requested',
  salePriceRange: 'Sale Price Range',
  purchasePriceRange: 'Purchase Price Range',
  from: 'From',
  to: 'To',
  skuCode: 'SKU Code',
  size: 'Size',
  lengthRange: 'Length Range',
  productSKU: 'Product - SKU',
  packageSize: 'Package Size',
  weight: 'Weight',
  salePrice: 'Sale Price',
  purchasePrice: 'Purchase Price',
  otherProductInformation: 'Other product information',
  countryCode: 'Country Code',
  description: 'Description',
  alternativeName: 'Alternative Name',
  characteristics: 'Characteristics',
  length: 'Length',
  width: 'Width',
  height: 'Height',
  requestCreateNewProduct: 'Request Create New Product',
  warningCreateProduct:
    'Please fill in all the information and click Submit to request the creation of a new product.',
  packageUnit: 'Package Unit',
  productImage: 'Product Image',
  productLink: 'Product Link',
  widthRange: 'Width Range',
  heightRange: 'Height Range',
  weightRange: 'Weight Range',
  lengthLabel: 'Length',
  widthLabel: 'Width',
  heightLabel: 'Height',
  cm3Target: 'CM3 Target',
  requestLevel2Approve: 'Request Level 2 Approve',
  requestUser: 'Request User',
  approveUser: 'Approve User',
  level2Approve: 'Level 2 Approve',
  relatedDocuments: 'Related Documents',
  requestDate: 'Request Date',
  warehouseProvince: 'Warehouse Province',
  addSupplier: 'Add Suppliers',
  close: 'Close',
  packType: 'Pack Type',
  averagePriceLastMonth: 'Average Price Last Month',
  requestQuantity: 'Request Quantity',
  quotationQuantity: 'Quotation Quantity',
  discountPercent: 'Discount Percent',
  beforeDiscount: 'Before Discount',
  termAndCondition: 'Terms and Conditions',
  currentStatus: 'Current Status',
  creationTime: 'Creation Time',
  purchaseRequestNumber: 'Purchase Request Number',
  viewNote: 'View Note',
  viewTermsAndCondition: 'View Temps And Condition',
  min: 'Min',
  max: 'Max',
  select: 'Select',
  deselect: 'Deselect',
  specialCommission: 'Special Commission',
  vigoCommission: 'Vigo Commission',
  allocatedShippingFee: 'Allocated Shipping Fee',
  actualOfferPrice: 'Actual Offer Price',
  vigoOfferPrice: 'Vigo Offer Price',
  sellingPrice: 'Selling Price',
  supplierCode: 'Supplier Code',
  supplierName: 'Supplier Name',
  shippingProvince: 'Shipping Province',
  supplierDelivery: 'Supplier Delivery',
  overallProcess: 'Overall Process',
  clickView: 'Click to view',
  createProductSuccessTitle: 'Thank you for submitting this request!',
  createProductSuccessSub:
    'System will notify you when the product request results are available.',
  viewRequestedProduct: 'View requested product',
  selectPackageUnit: 'Please select one unit',
  selectCategories: 'Please select one or more categories',
  ex: 'EX',
  rejectReason: 'Reject Reason',
  suggest: 'Suggest',
  ratioAccept: 'Ratio accept',
  date: 'Date',
  contentEmptyDataSupplier:
    'No supplier with the <link>corresponding tax code found.</link>',
  createNow: 'Create Now',
  invoiceFile: 'Invoice File',
  supplierSupportDeliveryFee: 'Supplier Support Delivery Fee',
  estimateDeliveryDay: 'Estimate Delivery Days',
  credit: 'Credit',
  debtAmt: 'Debt Amount',
  numberDayDebt: 'Number Day Debt',
  searchTaxCode: 'Search tax code',
  supplierInfo: 'Supplier Information',
  contractNumber: 'Contact Number',
  addressOnInvoice: 'Address On Invoice',
  taxNumber: 'Tax Number',
  bankAccName: 'Bank Account Name',
  bankAccNumber: 'Bank Account Number',
  accName: 'Account Name',
  errorTaxCode: 'Tax Number already exists!',
  cancelSupplier: 'Cancel create supplier',
  placeholderSelectCategory: 'Please select one or more categories',
  selectPR: 'Select Purchase Request',
  messageSelectPR:
    'Click to add <link>one or more Purchase request</link> to create',
  addPR: 'Add Purchase Request',
  viewEdit: 'View/Edit Quotation',
  supplierDeliveryFee: 'Supplier Delivery Fee',
  confirmAndNext: 'Confirm & Next',
  creditInfo: 'Credit Information',
  disagree: 'Disagree',
  agreeAndConfirm: 'Agree and confirm',
  packageSizeRequest: 'Package Size',
  packageTypeRequest: 'Package Type',
  vigoPO: 'Vigo PO Number',
  distributorPO: 'Distributor PO Number',
  deliveryBy: 'Delivery By',
  clickHereToView: 'Click here to view',
  deliveryFrom: 'Delivery From',
  deliveryTo: 'Delivery To',
  deliveryCode: 'Delivery',
  deliveryInformation: 'Delivery Information',
  purchasedByFrom: 'Purchased By (From)',
  supplierTo: 'Supplier (To)',
  relatedDocumentsPO: 'Related Documents',
  purchaseDetails: 'Purchase Details',
  shipment: 'Shipment',
  noOfItem: 'No. of item',
  debtAmount: 'debt Amount',
  deliveredBy: 'Delivered By',
  creditInformation: 'Credit Information',
  printDeliveryOrInbound: 'Print Delivery/Inbound',
  inboundInformation: 'Inbound Information',
  replacedBy: 'Replaced By DPO',
  attachedFile: 'Attached file',
  purchaseOrderNumber: 'Purchase Order Number',
  reasonReject: 'Reason Reject',
  dateInvoice: 'Invoice Date',
  invoiceMediaID: 'Invoice Media ID',
  paymentNote: 'Payment Note',
  confirmRecordDebt: 'Confirm Debt',
  debtReminder: 'Debt Reminder Date',
  debtTotalAmount: 'Debt Amount',
  debtDueDate: 'Due Date',
  debtReminderDate: 'Debt Reminder Date',
  totalActualValue: 'Collected Amount',
  newPurchasePrice: 'Purchase Price',
  newPurchasePriceVAT: 'Purchase Price (VAT)',
  documents: 'Documents',
  province: 'Province',
  estimateSaleAgentCommission: 'Estimate Sale Agent Commission',
  infoExport: 'The system will default to exporting the last 3 months.',
  errorExport:
    'The system will default to exporting data for the last 3 months (when the user does not select a date filter) and up to 6 months (when the user selects a date filter).',

  //Date
  [WORKING_DAY_MONDAY]: 'Monday',
  [WORKING_DAY_TUESDAY]: 'Tuesday',
  [WORKING_DAY_WEDNESDAY]: 'Wednesday',
  [WORKING_DAY_THURSDAY]: 'Thursday',
  [WORKING_DAY_FRIDAY]: 'Friday',
  [WORKING_DAY_SATURDAY]: 'Saturday',
  [WORKING_DAY_SUNDAY]: 'Sunday',
  //Menu
  [NOTIFICATION]: 'Notification',
  [SUBMIT_BIDDING]: 'Submit Bidding',
  [PURCHASE_REQUEST]: 'Purchase Request',
  [VIGO_QUOTATION]: 'Vigo Quotation',
  [DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB]: 'Distributor PurchaseOrder',
  [PAYMENT_REQUEST_BREADCRUMB]: 'Payment Request',
  //Menu Master Data
  [MASTER_DATA]: 'Master Data',
  //Menu product
  [MENU_PRODUCT]: 'Product',
  [PRODUCT_AVAILABLE]: 'Product Available',
  [PRODUCT_LIST_VN]: 'Product',
  [SUPPLIER_PRODUCT_LIST_VN]: 'Supplier Product',
  //Partner
  [PARTNER]: 'Partner',
  // Distributor profile
  [DISTRIBUTOR_MANAGEMENT]: 'Distributor Management',
  [DISTRIBUTOR_REGISTER]: 'Distributor Register',
  [DISTRIBUTOR_PROFILE]: 'Distributor Profile',
  //Children Master Data
  [DISTRIBUTOR_LIST]: 'Distributor',
  [DISTRIBUTOR_MARGIN]: 'Distributor Margin',
  [INVENTORY_RECOMMEND]: 'Config recommend source of goods',
  [PRODUCT_PRE_ORDER]: 'Product Pre-Order',
  [PRODUCT_PRE_ORDER_INDIA]: 'Product Pre-Order',
  [PRODUCT_LIST]: 'Product',
  [PRODUCT_LISTING]: 'Product Listings',
  [SUPPLIER_LIST]: 'Supplier',
  [SUPPLIER_LIST_VN]: 'Supplier',
  [SUPPLIER_PRODUCT_LIST]: 'Supplier Product',
  [WAREHOUSE_LIST]: 'Warehouse',
  [PRODUCT_TRADING_BY_LISTING]: 'Product Trading By Listing',
  [REASON_NEED_ADJUST]: 'Reason Need Adjust',
  //Menu Supplier Portal
  [SUPPLIER_PORTAL]: 'Supplier Portal',
  [RETAIL_PURCHASE_REQUEST_LIST]: 'Purchase Request',
  //Children Supplier  Portal
  [PURCHASE_ORDER_LIST]: 'Purchase Order',
  [BIDDING_REQUEST]: 'Bidding Request',
  [BIDDING_INVITATION_LIST]: 'Bidding Invitation',
  //Menu Brand Portal
  [BRAND_PORTAL]: 'Brand Portal',
  [SALES_PERFORMANCE]: 'Sales Performance',
  [CATEGORY_INSIGHTS]: 'Category Insights',
  [DELIVERY_PERFORMANCE]: 'Delivery Performance',
  [INVENTORY_REPORT]: 'Inventory Report',
  [AGENTS_PERFORMANCE]: 'Agents Performance',
  [OUTLETS_PERFORMANCE]: 'Outlets Performance',
  [COVERAGE_REPORT]: 'Coverage Report',
  [PRICE_MANAGEMENT]: 'Price Management Report',
  [MISSION_PERFORMANCE]: 'Mission Performance Report',

  [DISTRIBUTOR_CENTER]: 'Distributor Center',
  [PURCHASING]: 'Purchasing',

  [PROCUREMENT]: 'Procurement',
  [AUTOMATIC_PARTIAL_FULFILLMENT]: 'Ratio Automatically Fulfillment',
  [REASON_CANCEL]: 'Reason Cancel',
  [AUTOMATIC_PROCUREMENT_CONFIG]: 'Automatic Procurement Config',
  [DISTRIBUTOR_PURCHASE_REQUEST]: 'Distributor Purchase Request',
  [CONSOLIDATED_PURCHASE_REQUEST]: 'Consolidated PR',
  [VIGO_MASTER_QUOTATION]: 'Vigo Master Quotation',
  [DISTRIBUTOR_PURCHASE_ORDER]: 'Distributor Purchase Order',
  [VIGO_PURCHASE_ORDER]: 'Vigo Purchase Order',
  [LOGISTICS]: 'Logistics',
  [TRANSPORTATION_VENDOR]: 'Transportation Vendor',
  [VIGO_DELIVERY]: 'Vigo Delivery',
  [GOODS_NOT_FOCUS]: 'Goods Not Focus',
  [CONFIG_CM3_TARGET]: 'Config CM3 target',
  //WMS
  [WMS]: 'WMS',
  [WAREHOUSE_WMS_LIST]: 'Warehouse',
  [STOCK_TRANSFER]: 'Stock Transfer',
  [INBOUND_LIST]: 'Inbound',
  [INVENTORY_LIST]: 'Inventory',
  [INVENTORY_ADJUSTMENT]: 'Inventory Adjustment',
  [AUTO_INVENTORY_REPLENISHMENT]: 'Auto Inventory Replenishment',
  [FULFILLMENTS]: 'Fulfillment',
  [ORDER_LIST]: 'Fulfillment Order',
  [PRE_ORDERS]: 'Pre Order',
  [TRADE_ORDERS]: 'Trade Order',
  [PICKLIST_ASSIGNMENT]: 'Picklist Management',
  [PICKLIST_CHECKOUT]: 'Picklist Checkout',
  [RETURN]: 'Return',
  [RETURN_ORDER_LIST]: 'Return Management',
  [CREDIT_DEBIT_NOTE]: 'Credit Debit Note Management',
  [FINANCE]: 'Finance',
  [BUY_IN_INVOICE]: 'Buy-in Invoice',
  [TAX_MANAGEMENT]: 'Tax Management',
  [SUPPLIER_INVOICE_PRODUCT_MANAGEMENT]: 'Supplier Invoice Product Mapping',
  [DISTRIBUTOR_CONTRACT_FEE]: 'Distributor Contract Fee',
  [SELL_OUT_INVOICE]: 'Sell-out Invoice',
  [PAYOUT_STATEMENT]: 'SA Payout statement',
  [FINANCE_TASK_LIST]: 'SA Tasks',
  [PAYMENT_REQUEST]: 'Payment Request',
  [PRICE_TYPE_CONFIG]: 'Price Type For Vigo Commission Calculation',
  [CLAIMS_MANAGEMENT]: 'Claims Management',
  [CLAIMS]: 'Claims',
  [CLAIMS_INVOICE]: 'Claim Invoice',
  [RIDER]: isIndia ? 'Rider' : 'Delivery Management',
  [RIDER_MANAGEMENT]: 'Rider Management',
  [VEHICLE_MANAGEMENT]: 'Vehicle Management',
  [DELIVERY_MANAGEMENT]: isIndia
    ? 'Delivery Management'
    : 'Route Delivery Management',
  [DELIVERY_MANAGEMENT_HUB]: 'Delivery Management Hub',
  [FAILED_DELIVERY_RECEIVE]: 'Failed Delivery Receive',
  [PACKAGE_LIST]: 'Package',
  [REMITTANCE_LIST]: 'Remittance',
  [REPORT]: 'Report',
  [CREDIT]: 'Credit Management',
  [REPORT_RIDER]: 'Rider',
  [REPORT_GST_PURCHASES]: 'GST Purchases',
  [REPORT_GST_PURCHASE_REGISTER]: 'GST Purchase Register',
  [REPORT_PURCHASE_REGISTER_DETAILED]: 'Purchase Register Detailed',
  [REPORT_PURCHASE_RETURN]: 'Purchase Return',
  [REPORT_GST_SALES]: 'GST Sales',
  [REPORT_GSTR_SALES_REGISTER]: 'GSTR Sales Register',
  [REPORT_GST_SALES_RETURN]: 'GST Sales Return',
  [REPORT_GST_SALES_HUB]: 'GST Sales Hub',
  [REPORT_GST_SALES_REGISTER_HUB]: 'GST Sales Register Hub',
  [REPORT_GST_SALES_RETURN_HUB]: 'GST Sales Return Hub',
  [REPORT_GST_SALES_REGISTER_DETAILED_HUB]: 'GST Sales Register Detailed Hub',
  [REPORT_SUMMARY]: 'Summary',
  [REPORT_GST_SUMMARY_BY_HSN]: 'GST Summary By HSN',
  [REPORT_INVOICE_LOADING]: 'Invoice Loading',
  [REPORT_PRODUCT_SUMMARY]: 'Product Summary',
  [REPORT_INVENTORY]: 'Inventory',
  [REPORT_INVENTORY_SNAPSHOT]: 'Inventory Snapshot',
  [REPORT_PURCHASE_RECONCILIATION]: 'Purchase Reconciliation',
  [REPORT_CLAIMS]: 'Claims',
  [REPORT_CLAIM]: 'Claims Report',
  [REPORT_CLAIMS_RETURN]: 'Claims Return',
  [REPORT_DISTRIBUTOR_RECONCILIATION]: 'Distributor Reconciliation',
  [REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY]:
    'Distributor Reconciliation Summary',
  [REPORT_SALES_SUMMARY]: 'Sales Summary',
  [REPORT_SALES_SUMMARY_SKU]: 'SKU',
  [REPORT_SALES_SUMMARY_OUTLET]: 'Outlet',
  [REPORT_GST_SALES_ADMIN]: 'GST Sales (Admin)',
  [REPORT_GST_SALES_REGISTER_ADMIN]: 'Sales Register (Admin)',
  [REPORT_GST_SALES_RETURN_ADMIN]: 'Sales Return (Admin)',
  [MARKETING]: 'Marketing',
  [MARKETING_BUDGET]: 'Budget',
  [SUBSIDY_RULE]: 'Subsidy Rule',
  [AGENT_INCENTIVES]: 'Agent Incentives',
  [AGENT_GROUP]: 'Agent Group',
  [INCENTIVES_CONFIG]: 'Incentive Config',
  [SETTINGS]: 'Settings',
  [USER_LIST]: 'Users',
  [TASK_LIST]: 'Tasks',
  [PROCUREMENT_CONFIG]: 'Procurement',
  [SA_PAYOUT_STATEMENT_DETAIl]: 'SA Payout Statement Detail',
  [UPDATE_WAREHOUSE_DETAILS]: 'Update Warehouse Details',
  //outlet
  [OUTLET_MANAGEMENT]: 'Outlet Management',
}

export const en = {
  [HOME]: HOME_EN,
  [COMMON]: common,
  [PURCHASING]: PURCHASING_EN,
  [PURCHASE_REQUEST]: PURCHASE_REQUEST_EN,
  [VIGO_QUOTATION]: VIGO_QUOTATION_EN,
  [DISTRIBUTOR_PURCHASE_ORDER_BREADCRUMB]: DISTRIBUTOR_PURCHASE_ORDER_EN,
  [PAYMENT_REQUEST_BREADCRUMB]: PAYMENT_REQUEST_EN,
  [PRODUCT_PRE_ORDER]: PRODUCT_PRE_ORDER_EN,
  [TASK_LIST]: TASK_EN,
  [REASON_NEED_ADJUST]: REASON_NEED_ADJUST_EN,
  [PRODUCT_LISTING]: PRODUCT_LISTINGS_EN,
  [PRODUCT_TRADING_BY_LISTING]: PRODUCT_TRADING_BY_LISTING_EN,
  [INBOUND]: INBOUND_EN,
  [INVENTORY]: INVENTORY_EN,
  [ORDERS]: ORDERS_EN,
  [PRE_ORDERS_TRANSLATE]: PRE_ORDERS_EN,
  [TRADE_ORDERS_TRANSLATE]: TRADE_ORDERS_EN,
  [PICKLIST]: PICKLIST_EN,
  [AUTO_INVENTORY_REPLENISHMENT]: AUTO_INVENTORY_REPLENISHMENT_EN,
  [RIDERS]: RIDER_EN,
  [VEHICLE]: VEHICLE_EN,
  [DELIVERY]: DELIVERY_EN,
  [PACKAGE]: PACKAGE_EN,
  [REMITTANCE]: REMITTANCE_EN,
  [RIDER_REPORT]: REPORT_RIDER_EN,
  [BUY_IN_INVOICE_KEY]: BUY_IN_INVOICE_EN,
  [SELL_OUT_INVOICE_KEY]: SELL_OUT_INVOICE_EN,
  [SUBSIDY]: SUBSIDY_EN,
  [PO]: PO_EN,
  [BIDDING_REQUEST_KEY]: BIDDING_REQUEST_EN,
  [BIDDING_INVITATION_KEY]: BIDDING_INVITATION_EN,
  [WAREHOUSE]: WAREHOUSE_EN,
  [BUDGET]: BUDGET_EN,
  [DISTRIBUTOR_PROFILE_MANAGEMENT]: DISTRIBUTOR_PROFILE_EN,
  [AUTOMATIC_PROCUREMENT_CONFIG]: AUTOMATIC_CONFIG_EN,
  [STOCK_TRANSFER]: STOCK_TRANSFER_EN,
  [SIGN_UP]: SIGN_UP_EN,
  [PRODUCT]: PRODUCT_EN,
  [CONTRACT_FEE]: DISTRIBUTOR_CONTRACT_FEE_EN,
  [CONSOLIDATED_PR]: CONSOLIDATED_PR_EN,
  [VIGO_MASTER]: VIGO_MASTER_QUOTATION_EN,
  [VIGO_PO]: VIGO_PURCHASE_ORDER_EN,
}
